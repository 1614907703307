$('[data-toggle="menu"]').click(function(){
	var target = $(this).attr('data-target');
	var bgMenuOpened = document.createElement('div');

	bgMenuOpened.className = 'bg-menuOpened';

	bgMenuOpened.onclick = function(){
		$(target).removeClass('open');
		$('body, html').removeClass('lock-scroll');

		$(this).fadeOut(600, function(){
			$(this).remove();
		});
	}

	document.querySelector(target).appendChild(bgMenuOpened);

	$(bgMenuOpened).fadeIn(600);

	$(target).toggleClass('open');
	$('body, html').toggleClass('lock-scroll');

	$(this).blur();
});