function addResponsavel(event,c){
	let tplt = $(event.target).attr('data-template');
	let template = $(tplt).html();
	let parent = $(event.target).attr('data-parent');

	$(event.target).parents('.responsavel').addClass('remove-add-ctrl');

	template = template.replace(/{{ n }}/g, c);
	template = template.replace(/{{ c }}/g, (c+1));

	$(parent).append(template);
	$(event.target).blur();
}

function removeResponsavel(responsavel){
	$(responsavel).remove();
	$('.responsavel:last-child()').removeClass('remove-add-ctrl');
}