function startOwlCarousel(target,options){
	if($(target).children().length > 1){
		$(target).owlCarousel(options);
	}else{
		$(target).addClass('owl-loaded');
	}
}

startOwlCarousel('.carousel-produtos',{
	responsive: {
		992: {
			items: 4
		},
		600 : {
			items: 3
		},
		0 : {
			items: 1
		}
	},
	loop: true,
	autoplay:true,
	dots: false,
	nav: true,
	navText: [
		'<i class="fa fa-chevron-left"></i>',
		'<i class="fa fa-chevron-right"></i>'
	]
});