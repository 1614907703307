$.fn.isLazyImage = function(){
	return this.each(function(index,el){
		if(el.getBoundingClientRect().top < window.innerHeight + 200){
			if(!el.getAttribute('loaded')){
				var src = el.getAttribute('src') || el.getAttribute('data-src');
				var alt = el.getAttribute('alt') || el.getAttribute('data-alt');

				var img = document.createElement('img');

				if(el.getAttribute('data-classe')){
					var classe = el.getAttribute('data-classe') + ' img-responsive';
				}else{
					var classe = 'img-responsive';
				}

				img.setAttribute('src',src);
				img.setAttribute('alt',alt);
				img.setAttribute('class',classe);


				el.appendChild(img);
				el.setAttribute('loaded','true');
			}
		}
	});
};

$.fn.lazyImage = function(){
	var jaLazyImage = false;
	return this.each(function(index, el){
		var lazy = el;

		$(lazy).isLazyImage();

		$(window).scroll(function(){
			if(jaLazyImage) return;

			setTimeout(function(){
				jaLazyImage = false;
			},100);

			$(el).isLazyImage();
		});
	});
};

$('lazyimage').lazyImage();
