// elementosCustom.define('lazy-picture', (function(){
// 	function LazyPicture(el){
// 		// $(el).css('display','block');
// 		el.setAttribute('style', 'display:block');
// 		var images = el.querySelectorAll('sr-image');
// 		var viewSize = [];

// 		if(!images){
// 			return false;
// 		}

// 		for(var i=0; i<images.length; i++){
// 			var media = $(images[i]).attr('media');
// 			viewSize[i] = {};

// 			viewSize[i].src = $(images[i]).attr('src');

// 			if(media){
// 				media = media.split(' and ');

// 				if(media.length > 1){
// 					media = media;
// 					var mediaMin = media[0].replace('(min-width:','');
// 					mediaMin = mediaMin.replace('px)','');
// 					mediaMin = parseInt(mediaMin);

// 					var mediaMax = media[1].replace('(max-width:','');
// 					mediaMax = mediaMax.replace('px)','');
// 					mediaMax = parseInt(mediaMax);

// 					if(window.innerWidth >= mediaMin){
// 						viewSize[i].mediaMin = mediaMin;
// 						viewSize[i].mediaMax = mediaMax;
// 					}
// 				}else{
// 					media = media[0].replace('(','');
// 					media = media.replace(')','');
// 					media = media.split(':');

// 					switch(media[0]){
// 						case 'min-width':
// 							var mediaMin = media[1].replace('px');
// 							mediaMin = parseInt(mediaMin);

// 							if(window.innerWidth >= mediaMin){
// 								viewSize[i].mediaMin = mediaMin;
// 								viewSize[i].mediaMax = 999999;
// 							}
// 						break;
// 						case 'max-width':
// 							var mediaMax = media[1].replace('px');
// 							mediaMax = parseInt(mediaMax);

// 							viewSize[i].mediaMin = 0;
// 							viewSize[i].mediaMax = mediaMax;
// 						break;
// 					}
// 				}
// 			}

// 			// $(images[i]).remove();
// 		}

// 		var calcSize = function(el,viewSize) {
// 			var windowWidth = window.innerWidth;

// 			$(el).find('lazyimage').remove();

// 			for(var n=0;n<viewSize.length;n++){
// 				if((windowWidth >= viewSize[n].mediaMin) && (windowWidth <= viewSize[n].mediaMax)){


// 					var img = document.createElement('lazyimage');
// 					img.setAttribute('src', viewSize[n].src);
// 					img.setAttribute('alt', el.getAttribute('alt'));

// 					var hasLazyimage = el.querySelector('img');

// 					if(hasLazyimage){
// 						$(hasLazyimage).attr('src', viewSize[n].src);
// 					}else{
// 						$(el).append('<lazyimage src="'+viewSize[n].src+'" alt="teste"></lazyimage>');
// 						$(el).find('lazyimage').lazyImage();
// 					}
// 				}
// 			}
// 		}

// 		var jaCalc = false;

// 		calcSize(el,viewSize);

// 		window.addEventListener('resize', function(){
// 			if(jaCalc) return;

// 			jaCalc = false;

// 			calcSize(el,viewSize);

// 			setTimeout(() => {
// 				if(el.innherHTML == ''){
// 					calcSize(el,viewSize);
// 				}
// 			}, 100);

// 		});
// 	}

// 	return LazyPicture;
// })());
