$('.buscas .btn').click(function(event) {
	event.preventDefault();

	var value = $('#buscas').val();

	if(value == ''){
		$('.buscas').toggleClass('focus');

		if($('#buscas').has(':focus')){
			$('#buscas').focus();
		}else{
			$('#buscas').blur();
		}
	}else{
		$('.buscas').submit();
	}
});

$('#buscas').blur(function(){
	if($(this).val() == ''){
		$('.buscas').removeClass('focus')
	}
});