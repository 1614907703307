$.fn.affixTop = function(params){
	return this.each(function(){
		var este = this;

		if($(window).scrollTop() >= params.offset.top){
			$(este).addClass('fixed');

			if(params.on.affixTop){
				params.on.affixTop();
			}
		}else{
			$(este).removeClass('fixed');
			if(params.on.affixedTop){
				params.on.affixedTop();
			}
		}
	});
};

$.fn.affix = function(params){
	return this.each(function(){
		var este = this;
		var jaAffix = false;

		$(este).affixTop(params);

		$(window).scroll(function(){
			if (jaAffix) return;

			setTimeout(()=>{
				jaAffix = false;
			},100);

			$(este).affixTop(params);
		});
	});
};


$('#menu').affix({
	offset: {
		top: $('.bar-topo').outerHeight()
	},
	on: {
		affixTop: function(){
			$('.bar-topo').css('margin-bottom', $('#menu').outerHeight());
		},
		affixedTop: function(){
			$('.bar-topo').removeAttr('style');
		}
	}
});