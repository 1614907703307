$('a[data-toggle="collapse"]').click(function(){
	$(this).parent().toggleClass('active');
});

$('[data-toggle="ordenarPor"]').click(function(){
	var target = $(this).attr('data-target').split(',');
	var value =  $(this).attr('data-value');

	$(target[0]).val(value);
	$(target[1]).text(value);

	$(this).siblings('li').removeClass('active');
	$(this).addClass('active');
});

$('[data-toggle="mostrar"]').click(function(){
	var target = $(this).attr('data-target').split(',');
	var value =  $(this).attr('data-value');

	$(target[0]).val(value);
	$(target[1]).text(value);

	$(this).siblings('li').removeClass('active');
	$(this).addClass('active');
});