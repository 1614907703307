$('iframe').each(function(){
	var parent = this.parentNode;

	if(!$(parent).hasClass('embed-responsive')){
		var w = $(this).outerWidth();
		var h = $(this).outerHeight();

		var scale = (h / w) * 100;

		$(this).wrap('<div class="embed-responsive" style="padding-bottom:'+scale+'%"></div>');
	}
});